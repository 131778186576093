* {
  color: #fff;
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
  background-color: #000;
  border-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.album, .album * {
  background-color: #0a0a0a;
}

.album-title, .album-info {
  font-size: 32px;
}

.track, .track * {
  background-color: #101010;
}

.track-title, .track-info {
  font-size: 24px;
}

.album-title, .track-title {
  font-weight: 700;
}

.album-info, .track-info {
  float: right;
  font-weight: 500;
}

.track-clipboard {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
}

.album-title, .track-title {
  text-decoration: none;
}

.album, .track {
  margin: 5px;
  padding: 5px;
}

.album, .album *, .track, .track * {
  border-radius: 20px;
}

.lyrics {
  white-space: pre-wrap;
}
/*# sourceMappingURL=index.72275499.css.map */
